* {
    font-family: "Roboto", sans-serif;
    padding: 0;
    margin: 0;
  }
  
  .ui.cards > .card > .image {
    height: 250px;
    padding: 20px;
    background: #fff;
    margin: auto;
  }
  .ui.cards > .card > .image > img {
    height: 100%;
    max-width: 100%;
    width: auto;
  }
  .ui.cards > .card > .content > .header {
    height: 48px;
    overflow: hidden;
    margin-bottom: 5px;
  }
  .ui.cards > .card > .content > .description {
    height: 36px;
    margin-bottom: 0px;
    overflow: hidden;
  }
  .ui.cards > .card .meta.price {
    margin-bottom: 5px;
    font-size: 18px;
    color: #333;
    font-weight: 600;
  }
  
  .ui.cards > .card .meta.price > a {
    font-size: 1.3rem;
    color: #222;
  }
  
  .ui.menu.fixed {
    height: 60px;
    padding-top: 15px;
  }
  
  .ui.grid.container {
    margin-top: 45px;
  }
  
  .ui.grid > .row {
    background: #fff;
  }
  .ui.grid > .row > .column.lp {
    padding: 20px 40px 20px 20px;
    align-self: flex-start !important;
  }
  .ui.grid > .row > .column.rp {
    padding: 20px 20px 20px 40px;
    text-align: left;
    align-self: flex-start !important;
  }
  
  .ui.grid > .row > .column > img,
  .ui.grid > .row > img {
    height: 100%;
  }
  .ui.placeholder .header:not(:first-child):before,
  .ui.placeholder .image:not(:first-child):before,
  .ui.placeholder .paragraph:not(:first-child):before {
    display: none;
  }
  
  .ui.label,
  .ui.labels .label {
    font-size: 22px;
  }
  
  .column.rp h1 {
    color: #333;
  }
  .column.rp p {
    font-size: 18px;
    color: #777;
  }
  .ui.placeholder.segment .column .button,
  .ui.placeholder.segment .column .field,
  .ui.placeholder.segment .column textarea,
  .ui.placeholder.segment .column > .ui.input {
    background-color: #ff3e6c;
    border: 1px solid #ff3e6c;
    color: #fff;
    font-size: 18px;
    margin-left: 0;
  }
  /* responsive css */

  /* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

    .row {
       
        display: flex;
        flex-wrap: nowrap;
        margin-right: -15px;
        margin-left: -15px;
    }
}

.remove-border {
  border-bottom: 0 none !important;
  box-shadow: none;
}

/* mobile device */

@media only screen and (max-width: 768px) {
 .ui.cards > .card > .image {
    height: 150px;
    width: 100px;
    background: #fff;
    margin: auto;
  }

  .ui.cards > .card .meta.price {
    margin-bottom: 5px;
    font-size: 18px;
    color: #333;
    font-weight: 500;
    display: flex;
  }

  .ui.cards > .card > .content > .header {
    overflow: hidden;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    display: inline-grid;
}

}