body {

  background-repeat: no-repeat;
  background-size: cover;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.form-container {
  margin: auto;
  width: 75%;
  padding: 10px;
  box-shadow: none;
  border: none;
  color: white;
  font-weight: 900;
  /* background-image: url("../../../public/assets/form-bg.jpg"); */
}

.form-control {
  color: black;
  font-weight: 900;
}

.message {
   font-size: 18px;
  font-weight: 900;
}
