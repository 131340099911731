.underline{
  height: 10px;
  background: #43C6AC;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #191654, #43C6AC);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #191654, #43C6AC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

/* rotating css */

.rotate {
  animation: rotation 6s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}


.custom-font{
  font-family: 'Times New Roman', Times, serif;
}


/* modal css */
@media (max-width: 575.98px) {
  .modal-fullscreen {
    padding: 0 !important;
  }
  .modal-fullscreen .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-sm {
    padding: 0 !important;
  }
  .modal-fullscreen-sm .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-md {
    padding: 0 !important;
  }
  .modal-fullscreen-md .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-lg {
    padding: 0 !important;
  }
  .modal-fullscreen-lg .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg .modal-body {
    overflow-y: auto;
  }
}
.modal-fullscreen-xl {
  padding: 0 !important;
}
.modal-fullscreen-xl .modal-dialog {
  width: 100%;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen-xl .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen-xl .modal-body {
  overflow-y: auto;
}

/* html {
  display: flex;
  height: 100%;
} */

/* body {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

.btn-open-modal {
  margin-bottom: 0.5em;
}

.creative{
height: 250px;
background-image: url(../public/assets/animations/giphy4.gif);
background-size: cover;
background-repeat: no-repeat;
background-position: center;
}

/* custom services page card reset styling sheets  */

.custom-card-img{

  width: 100px;
  height: 100px;
  margin: auto;
  padding-top: 5px;
  
}

.custom-card{
  border: none;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 40px;
}

.custom-card-title{

  font-weight: 900;
}


.service-picha{

  background-position: center;
  height: 400px;
  border-radius: 70px;
}


.custom_container{

 padding: 10px;

}
.borderr-none{
  border: none !important;

}

.custom_padding{

  padding-top: 60px;
}

.hide {
  display: none;
}
.show {
  display: block;
}
